<template>
  <div class="table-responsive">
    <table class="table">
      <thead class="thead-light">
        <th>Name</th>
        <th>SKU</th>
        <th>Created</th>
        <th>Retail Price</th>
        <th>Promo Price</th>
        <th>Stock</th>
        <th colspan="3">Active</th>
      </thead>
      <tbody>
        <tr v-if="model.length == 0">
          <td colspan="9" align="center">No data in this list</td>
        </tr>
        <div class="d-content" v-else v-for="(m, i) in model" :key="i">
          <tr class="bundle-collapse" :class="{'collapsed': m.collapsed}">
            <td>
              {{ m.name }}
              <font-awesome title="Souvenir" icon="tags" size="sm" class="mr-1" v-if="!m.status_purchaseable" />
              <template v-if="m.status_stock == false">
                <div class="badge badge-warning">PO</div>
              </template>
            </td>
            <td>
              {{ m.sku }}
            </td>
            <td>
              {{ formatDate(m.created_date) }}
            </td>
            <td>
              {{ m.bundle_dynamic_price ? 'Dynamic Price' : formatPrice(m.price) }}
            </td>
            <td>
              {{ m.bundle_dynamic_price ? formatPrice(Math.abs(m.promo_price)) : formatPrice(m.promo_price) }}
            </td>
            <td>
              <button type="button" class="btn btn-outline-secondary btn-block" :class="{'d-none' : m.stock.edit}" title="Edit Stock" @click="focusField(m, i)">{{formatPrice(m.stock.original)}}</button>
              <InputText v-model="m.stock.value" :id="`bundle-row-${i}`" type="number" maxlength="4" :classComponent="m.stock.edit ? 'mw-fit-content': 'd-none'" @input="setStock(i)" />
            </td>
            <td>
              <b-form-checkbox switch size="lg" v-model="m.is_active" @change="setActive($event, m)"></b-form-checkbox>
            </td>
            <!-- <td> -->
              <!-- <b-dropdown variant="link" toggle-class="p-0 text-black" right no-caret>
                <template #button-content>
                  <font-awesome icon="ellipsis-v" />
                </template>
                <b-dropdown-item role="button" :to="'/edit-bundle/' + m.id"><b>EDIT</b></b-dropdown-item>
                <b-dropdown-item role="button" v-b-modal.modal-delete-bundle @click="$emit('deleteBundle', m)"><b>DELETE</b></b-dropdown-item>
                <b-dropdown-item role="button" :to="'/detail-bundle/' + m.id"><b>VIEW</b></b-dropdown-item>
              </b-dropdown> -->
            <!-- </td> -->
            <td class="text-nowrap">
              <fawesome-pro variant="far" icon="edit" class="p-1 text-black cursor-pointer hover-warning" :to="'/edit-bundle/' + m.id" alt="Edit" title="Edit" />
              <fawesome-pro variant="far" icon="trash" class="p-1 text-black cursor-pointer hover-danger" v-b-modal.modal-delete-bundle @click.native="$emit('deleteBundle', m)" alt="Delete" title="Delete" />
              <fawesome-pro variant="far" icon="eye" class="p-1 text-black cursor-pointer hover-success" :to="'/detail-bundle/' + m.id" alt="View" title="View" />
              <font-awesome :icon="m.collapsed ? 'chevron-down' : 'chevron-up'" class="mx-1 cursor-pointer" alt="Toggle View Products" title="Toggle View Products" @click="toggleCollapse(i)"/>
            </td>
          </tr>
          <tr>
            <td colspan="9">
              <div class="d-flex align-items-center">
                Choices:
                <div class="ml-auto" v-if="m.changed">
                  <button type="button" class="btn btn-secondary ml-1" @click="resetState(i)"><fawesome-pro icon="undo" variant="far" class="pr-1" />Reset</button>
                  <button type="button" class="btn btn-success ml-1" @click="$emit('commitChanges', m)"><fawesome-pro icon="save" class="pr-1" />Save</button>
                </div>
              </div>
              <div class="d-flex mt-2">
                <!-- <div class="d-flex flex-column align-items-center mr-1" v-for="(item, j) in m.bundled_items.filter(el => el.bundle_product.length > 0)" :key="j"> -->
                <div :class="item.bundle_product && item.bundle_product.length > 0 ? 'd-flex' : 'd-none'" class="flex-column align-items-center mr-1" v-for="(item, j) in m.bundled_items" :key="j">
                  <div class="p-1 mb-1 border rounded text-white" :class="item.stock_check ? 'border-success bg-success' : 'border-secondary bg-secondary'">
                    <font-awesome :icon="item.stock_check ? 'shopping-bag' : 'tags'" /> {{item.stock_check ? 'Purchasables' : 'Souvenirs'}}
                  </div>
                  <div class="bundle-box" v-for="(product, k) in item.bundle_product" :key="k" >
                    <div class="bundle-img">
                      <b-img-lazy fluid
                        :class="{'default-image p-5' : product.product_images[0].thumbnail_image_url.indexOf('default-image') >= 0}"
                        :alt="product.name"
                        :title="product.name"
                        :src="product.product_images[0].thumbnail_image_url"
                        @error.native="replaceByDefaultImage(i, j, k)"
                      />
                      <i class="bundle-ico-del" @click="removeProduct(i, j, k)"></i>
                    </div>
                    <div class="bundle-info">
                      <small>
                        {{product.name}}<br/>
                        Color: {{product.product_detail_variance.map(el => el.color.name).filter((v, i, a) => a.indexOf(v) === i).join(", ").replace(/--none--/g, "-") || "-"}}<br/>
                        Size: {{product.product_detail_variance.map(el => el.size.international).filter((v, i, a) => a.indexOf(v) === i).join(", ").replace(/--none--/g, "-") || "-"}}
                      </small>
                    </div>
                  </div>
                  <b-img-lazy :src="require('@/assets/img/icon-add.png')" height="150" alt="Add a choice" title="Add a choice" class="cursor-pointer mb-1 bg-white shadow" v-b-modal.modal-add-to-bundle @click.native="$emit('editBundleProducts', {bundle: m, index: j})" />
                </div>
                <b-img-lazy :src="require('@/assets/img/icon-add.png')" height="150" alt="Add more product" title="Add more product" class="cursor-pointer mb-1 bg-white shadow" v-b-modal.modal-add-to-bundle @click.native="$emit('editBundleChoices', {bundle: m})" v-if="m.bundled_items.filter(el => el.bundle_product.length).length < 3" />
              </div>
            </td>
          </tr>
        </div>
      </tbody>
    </table>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import InputText from "@/components/formCustom/InputText";

import moment from "moment";

export default {
  name: "BundleListTable",
  props: ["items"],
  components: {
    InputText
  },
  data() {
    return {
      original: this.items,
      model: this.items,
    }
  },
  methods: {
    focusField(bundle, index) {
      bundle.stock.edit = true
      const el = document.querySelector(`#bundle-row-${index}`)
      el.addEventListener("keyup", (event) => {
        if (event.keyCode === 13) el.blur()
      })
    },
    toggleCollapse(index) {
      const model = this.model[index];
      model.collapsed = !model.collapsed;
      this.model.splice(index, 1, model);
    },
    async setStock(index) {
      // onblur
      this.model[index].stock.edit = false

      const bundle = this.model[index]
      if (bundle.stock.original == bundle.stock.value) toaster.make("Nothing changed")
      else {
        try {
          const res = await this.$api.bundle.editBundleStock(bundle.id, bundle.stock.value);
          if (res.status === 200) {
            toaster.make(res.data.message, "success")
            bundle.stock.original = bundle.stock.value
          } else toaster.make(res.data.message, "danger");
        } catch (e) {
          console.error(e);
        }
      }
    },
    async setActive(event, product) {
      try {
        const res = await this.$api.product.setActive(event, product.id)
        if (res.status === 200) {
          toaster.make(res.data.message, 'success')
          this.$emit('changeActive')
        } else {
          toaster.make(res.data.message, 'danger')
          product.is_active = !event
        }
      } catch (e) {
        console.error(e);
        product.is_active = !event
      }
    },
    removeProduct(indexBundle, indexChoice, indexProduct) {
      const bundle = this.model[indexBundle]
      const items = bundle.bundled_items[indexChoice]
      const removedProductId = items.bundle_product[indexProduct].id
      if (items) {
        const prodListIds = items.product_list_ids.match(/\[(.+)\]/)[1].split(",")
        items.product_list_ids = `[${prodListIds.filter(el => el != removedProductId).join(",")}]`
      }
      items.bundle_product.splice(indexProduct, 1)
      items.changed = true
      
      // if (this.model[indexBundle].bundled_items[indexChoice].bundle_product.length == 0)
      //   bundle.bundled_items.splice(indexChoice, 1)
      // else bundle.bundled_items.splice(indexChoice, 1, items)
      bundle.changed = true
    },
    resetState(indexBundle) {
      const bundle = JSON.parse(JSON.stringify(this.original[indexBundle]))
      bundle.collapsed = false
      this.model.splice(indexBundle, 1, bundle)
    },
    replaceByDefaultImage(indexBundle, indexChoice, indexProduct) {
      this.model[indexBundle]
        .bundled_items[indexChoice]
        .bundle_product[indexProduct]
        .product_images[0].thumbnail_image_url = require('@/assets/img/default-image.png')
    },
    formatDate(value) {
      return value ? moment(String(value)).format('YYYY-MM-DD HH:mm:ss').toString() : value
    },
    formatPrice(value, decimal = 0) {
      return value ? value.toLocaleString('id', {minimumFractionDigits: decimal}) : value
    },
  },
  watch: {
    items(newVal) {
      this.model = newVal.map(el => {
        el.collapsed = true
        el.changed = false
        el.stock = {
          edit: false,
          value: el.stock,
          original: el.stock,
        }
        return el
      })
      this.original = JSON.parse(JSON.stringify(this.model))
    },
  }
}
</script>
<style scoped>
thead th, td {
  vertical-align: middle;
}
tbody {
  font-size: .865rem;
  white-space: nowrap;
}
tbody p {
  line-height: 22px;
  margin-bottom: .5rem;
}
.td-dropdown {
  padding: 1rem .25rem;
}
@media (min-width: 992px) {
  tbody {
    white-space: normal;
  }
  .table-responsive {
    overflow: initial;
  }
}
.mw-400px {
  max-width: 400px;
  text-align: justify;
  white-space: normal;
}
</style>
