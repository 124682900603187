<template>
  <div class="container-fluid">
    <MobileGuide />
    <BundleNavTab />
    <div class="card p-2">
      <div class="row p-1 align-items-center">
        <div class="col-md-auto">
          <h2 class="roboto subtitle bold">Manage Bundle</h2>
        </div>
        <div class="col d-flex">
          <!-- <button type="button" class="btn btn-wz-export text-nowrap overflow-hidden" @click="exportCsv()">Export</button> -->
          <form
            @submit.prevent="searchBundle"
            class="input-search ml-lg-auto flex-grow-1 flex-lg-grow-0"
          >
            <input
              id="search"
              type="search"
              placeholder="Search Bundle Name"
              class="form-control"
            />
            <button class="btn"><font-awesome icon="search" /></button>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <BundleListTable
            :items="listAll.data"
            @changeActive="refreshList()" 
            @editBundleProducts="selectedBundle = $event"
            @editBundleChoices="selectedBundle = $event"
            @commitChanges="commitChanges"
            @deleteBundle="selectedBundle = $event"
          />
          <b-pagination
            class="float-right"
            v-model="listAll.page"
            :total-rows="listAll.total"
            :per-page="listAll.limit"
            @change="getBundles(listAll.limit, $event, search)"
          />
          <select
            v-model="listAll.limit"
            class="form-control w-auto float-right"
            @input="getBundles($event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>
    </div>
    <b-modal id="modal-add-to-bundle" size="sm" modal-class="p-0" centered hide-header hide-footer @hidden="product = null">
      <div class="row">
        <div class="col">
          <InputProduct
            v-model="productId"
            :options="selectedBundle && selectedBundle.index
              ? selectedBundle.bundle.bundled_items[selectedBundle.index].stock_check ? activeProducts.data : souvenirs.data
              : filterSouvenir ? souvenirs.data : activeProducts.data
            "
            :disabled="activeProducts.data.length == 0"
            @inputDetail="product = $event"
          />
        </div>
        <div class="col-auto pl-0 pt-2" v-if="isLoading">
          <font-awesome icon="spinner" spin/>
        </div>
      </div>
      <ProductBox :product="product" />
      <hr />
      <template v-if="selectedBundle && selectedBundle.index === undefined">
        <b-form-checkbox v-model="filterSouvenir" switch size="lg" class="d-inline" @input="product = null" />
        <p class="d-inline-block pt-1 m-0">Souvenirs?</p>
      </template>
      <div class="float-right">
        <button type="button" class="btn btn-success btn-sm ml-1" @click.once="editBundleFills(selectedBundle, product)" :disabled="!product"><fawesome-pro icon="check" /></button>
        <button type="button" class="btn btn-danger btn-sm ml-1" @click="$bvModal.hide('modal-add-to-bundle')"><fawesome-pro icon="times" /></button>
      </div>
    </b-modal>
    <b-modal
      id="modal-delete-bundle" hide-header centered
      @ok="deleteBundle(selectedBundle)"
    >
      <p class="text-center m-0">
        <b v-if="selectedBundle">{{ selectedBundle.name }}</b><br/>
        Are you sure you want to delete this bundle?
      </p>
    </b-modal>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";

import MobileGuide from "@/components/navs/MobileGuide";
import BundleNavTab from "@/components/bundle/BundleNavTab";
import BundleListTable from "@/components/bundle/BundleListTable";
const InputProduct = () => import("@/components/formCustom/InputProduct");
const ProductBox = () => import("@/components/product/ProductBox");

export default {
  name: "BundleList",
  components: {
    MobileGuide,
    BundleNavTab,
    BundleListTable,
    InputProduct,
    ProductBox,
  },
  data() {
    return {
      isLoading: false,
      selectedBundle: null,
      productId: 0,
      product: null,
      brands: [],
      activeProducts: {
        total: 0,
        data: [],
        limit: 5,
        page: 1,
      },
      filterSouvenir: false,
      souvenirs: {
        total: 0,
        data: [],
        limit: 5,
        page: 1,
      },
      search: null,
      listAll: {
        total: 0,
        data: [],
        limit: 5,
        page: 1,
      },
    };
  },
  mounted() {
    this.refreshList();
    this.getMerchantBrands()
    this.getActiveProducts()
    this.getActiveProducts(0, false)
  },
  methods: {
    refreshList() {
      this.getBundles(this.listAll.limit, 1, this.search)
    },
    searchBundle() {
      const el = document.getElementById("search");
      this.search = el.value;
      this.refreshList()
    },
    async deleteBundle(product) {
      try {
        const res = await this.$api.product.deleteProduct(product.id)
        if (res.status === 200) {
          toaster.make(res.data.message, "success")
          this.refreshList()
        }
      } catch (e) {
        console.error(e);
      }
    },
    async editBundleFills(srcbundle, product) {
      const { bundle, index } = srcbundle
      try {
        const res = await this.$api.product.getUserProduct(product.id)
        if (res.status === 200) {
          const { data } = res.data
          const temp = {
            id: data.id,
            name: data.name,
            product_detail_variance: data.product_detail_variance,
            product_images: data.product_images,
          }
          if (index !== undefined) {
            bundle.bundled_items[index].bundle_product.push(temp)
            bundle.bundled_items[index].product_list_ids =
              "[" + bundle.bundled_items[index].bundle_product.map(el => el.id).join(",") + "]"
            bundle.bundled_items[index].changed = true
          } else bundle.bundled_items.push({
            bundle_product: [temp],
            product_id: bundle.id,
            product_list_ids: `[${[product.id]}]`,
            stock_check: !this.filterSouvenir,
            changed: true,
          })
          bundle.changed = true
          this.$bvModal.hide('modal-add-to-bundle')
        } else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
    async commitChanges(model) {
      try {
      // OLD LOGIC
      // const listChanges = model.bundled_items.filter(el => el.changed).map(el => {
      //   const bundle = {
      //     product_id: el.product_id,
      //     product_list_ids: el.product_list_ids,
      //     stock_check: el.stock_check,
      //   }
      //   if (el.id) bundle.id = el.id
      //   return bundle
      // })

      // for (const i in listChanges)
      //   try {
      //     const data = listChanges[i]
      //     console.log("===========================")
      //     console.log("data", data);
      //     const res = await (data.id ? this.$api.bundle.editBundle(data) : this.$api.bundle.addBundle(data))
      //     if (res.status === 200) {
      //       toaster.make(res.data.message, "success")
      //       this.refreshList()
      //     } else toaster.make(res.data.message, "danger")
      //   } catch (e) {
      //     console.error(e);
      //   }

      // NEW LOGIC
      const listChanges = model.bundled_items.reduce((acc, curVal) => {
        const bundle = {
          product_id: curVal.product_id,
          product_list_ids: curVal.product_list_ids,
          stock_check: curVal.stock_check
        }
        if (curVal.id) bundle.id = curVal.id;

        if (!curVal.changed) return acc
        if (!curVal.id && curVal.product_list_ids == "[]") return acc //* Don't add new bundle option with empty product_list_ids

        acc.push(bundle);
        return acc
      }, []);

      if (!listChanges.length) return toaster.make("There are no changes", "info"); //* If there are no change on bundle option

      const statements = await Promise.all(listChanges.map(data => data.id ?  this.$api.bundle.editBundle(data) : this.$api.bundle.addBundle(data)))

      for (const res of statements) {
        if (res.status === 200) toaster.make(res.data.message, "success");
        else toaster.make(res.data.message, "danger");
      }

      this.refreshList();
      
      } catch (error) {
        console.error(error);
      }
    },
    async getBundles(limit, page, query) {
      try {
        const res = await this.$api.bundle.getBundles(limit, page - 1, query)
        if (res.status === 200) {
          this.listAll.total = res.data.meta.total;
          this.listAll.data = await this.getBundleItems(res.data.data);
          this.listAll.page = page;
        }
        else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
    async getBundleItems(bundle) {
      try {
        const res = await this.$api.bundle.getBundleItems()
        if (res.status === 200) {
          const { data } = res.data
          return bundle.map(el => {
            el["bundled_items"] = data.filter(rel => rel.product_id == el.id);
            return el
          })
        } else toaster.make(res.data.message, "danger")
        // return the bundle even if API call failed
        return bundle
      } catch (e) {
        console.error(e);
      }
    },
    async getMerchantBrands() {
      try {
        const res = await this.$api.cms.getBrands()
        if (res.status === 200) this.brands = res.data.data
        else toaster.make(res.data.message, "danger")
      } catch (e) {
        if (e.response) toaster.make(e.response, "danger")
        console.error(e);
      }
    },
    async getActiveProducts(page = 0, purchasable = true) {
      if (this.brands.length == 0)
        return setTimeout(() => this.getActiveProducts(page, purchasable), 100)
      this.isLoading = true
      try {
        const res = await this.$api.product.getProductsByBrand(50, page, null, this.brands.map(el => el.brand.id), purchasable, 3)
        if (res.status === 200) {
          if (purchasable) {
            this.activeProducts = {
              meta: res.data.meta,
              data: [...this.activeProducts?.data ?? [], ...res.data.data.result],
            }
            if (this.activeProducts.meta.limit * (this.activeProducts.meta.offset + 1) < this.activeProducts.meta.total)
              return this.getActiveProducts(++this.activeProducts.meta.offset, purchasable)
          } else {
            this.souvenirs = {
              meta: res.data.meta,
              data: [...this.souvenirs?.data ?? [], ...res.data.data.result],
            }
            if (this.souvenirs.meta.limit * (this.souvenirs.meta.offset + 1) < this.souvenirs.meta.total)
              return this.getActiveProducts(++this.souvenirs.meta.offset, purchasable)
          }
          this.isLoading = false
        }
        else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
  }
}
</script>
